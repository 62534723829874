<template>
  <div>
    <img alt="JJ logo" src="logo.png" />
    <h1>Jordi Jacobs</h1>
    <p>Software Engineer</p>
    <h3>Info</h3>
    <ul>
      <li>
        <a href="https://www.linkedin.com/in/jordi-jacobs-591b719a/">LinkedIn</a>
      </li>
      <li>
        <a href="cv.pdf">Cv</a>
      </li>
      <li>
        <a href="https://g.page/jacobs-jordi">Google</a>
      </li>
    </ul>
    <h3>Contact</h3>
    <p>jacobs.jordi@gmail.com</p>
    <p>BE 0758 765 969</p>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
